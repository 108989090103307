@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');


.botones {
    border-radius: 0.7rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}
.dentro-botones{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center; 
}
.h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 22px !important;
    text-align: center;
    font-weight: bold;
    color: white;
}
.h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 20px  !important;
    font-weight: bold;
    color: white;
    }
.h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 15px  !important;
    color: white;
}
.h4 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px !important;
    color: white;
}
.tarjeta-blanca{
    padding: 1rem !important;
    background-color: #430218;
    border-radius: 1rem;
    width: 100%;
    margin-bottom: 2rem;
} 
li{
    color: white;
 margin-left: 0.4rem;

}
.back-to-top{
display: flex;
justify-content: flex-end;
border:none;
background-color: none;
}
.back-to-top-img{
width: 3rem;
margin: 1rem;
    }
.icon{
    width: 2rem;
}
.botn{
    display: flex;
    justify-content: flex-end ;
}
button{
border:none;
background-color: none;
}
.auth-prin{
    background-color: #8D113B;
}
.logo-prin{
    width: 15rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.text-label{
    color:#FFADA5;
    font-family: 'Fredoka One', cursive;
    
}
.button-principal{
    background-color:#fabb1a;
    height: 2.5rem;
    color: white;
    font-family: 'Fredoka One', cursive;
    margin-top: 2rem;
}
.boton-div-f{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.button-f{
    background-color: #ff9700;
    border-radius: 0.5rem;
    width: 30vh;
    height: 2.5rem;
    color: white;
    font-family: 'Fredoka One', cursive;
    margin: 0.2rem;
}
.button-f2{
    background-color:#FD854D !important;
    border-color: #CC781C !important;
    color: white !important;
    font-family: 'Fredoka One', cursive !important;
    margin-top: 2rem !important;  
}

.center-l{
    display: flex;
    justify-content: center;

}
.left-sidebar{
    background: #430218 !important;
}
.sidebar-item {
    background: #430218 !important;
}
.sidebarnav {
    background: #430218 !important;
}
.navbar-header{
    background: #430218 !important;
}
.navbarbg {
    background: #430218 !important; 
}
.img-boton{
    width: 10rem;
}
.botones-div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}
a{
    text-decoration: none !important;
    color: white !important;
}
.saludo {
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-direction: column;
    background-color:#902239;
    border-radius: 2rem;
    padding: 0.5rem;
    padding-left: 1.5rem;
    margin: 1rem
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }
label{
    color:#FFADA5 !important;

}
th{
    color:#FFADA5 !important;
}
td{
    color:#FFADA5 !important;
}
@media (min-width: 600px) {
.botones-div{
    margin-top: 5rem;
    margin-bottom: 5rem;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;

}
}

/*tabs*/
.react-tabs__tab-list{
    border-bottom: 1px solid rgba(170, 170, 170, 0) !important;
    font-family: 'Fredoka One', cursive !important;
    
}
.react-tabs__tab--selected {
    background: #e6654a !important;
    border-color: #ea6969 !important;
    color: white !important;
    font-family: 'Fredoka One', cursive !important;
    border-radius: 2rem !important;
}
table {
    color:white !important
}
/* .cabezera{
    background-color: tomato;
} */


/********home********/
.boton-principal {
    background-color: #FD854D;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 0.5rem;
    border-radius: 4%;
    width: 98%;
    border: 5px solid #FD854D;
    border-radius: 12px;
    box-shadow: 6px 6px 6px rgb(14 4 0 / 26%);
    padding: 0;
  }
  .card-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .boton-principal:hover{
    opacity: 0.50;
    -moz-opacity: .50;
    filter:alpha (opacity=50);
  
  }
  .img-logo-g{
margin-right: 1.5rem;
  }
  .boton-principal-22 {
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 0.5rem;
    border-radius: 4%;
    width: 98%;
    border-bottom: 5px solid #232020;
    border-radius: 12px;
    box-shadow: 6px 6px 6px rgb(255 255 255 / 26%);
    padding: 1rem;
  }
  
  .boton-principal-22:hover{
    opacity: 0.50;
    -moz-opacity: .50;
    filter:alpha (opacity=50);
  
  }
  .boton-principal-2233 {
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 4%;
    width: 98%;
    border-bottom: 5px solid #232020;
    border-radius: 12px;
    box-shadow: 6px 6px 6px rgb(255 255 255 / 26%);
    padding: 2.5rem;
    margin-bottom: 5rem;
    margin-top: 1rem;
  }
  
  .boton-principal-2233:hover{
    opacity: 0.50;
    -moz-opacity: .50;
    filter:alpha (opacity=50);
  
  }
  .boton-principal-des {
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 0.5rem;
    border-radius: 4%;
    width: 98%;
    border-bottom: 5px solid #232020;
    border-radius: 12px;
    box-shadow: 6px 6px 6px rgb(255 255 255 / 26%);
    padding: 2rem;
  }
  
  .boton-principal-des:hover{
    opacity: 0.50;
    -moz-opacity: .50;
    filter:alpha (opacity=50);
  
  }
  
  
  .boton-principal2 {
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 2rem;
    border-radius: 4%;
    width: 90%;
    border-bottom: 5px solid #232020;
    border-radius: 10rem;
    box-shadow: 6px 6px 6px rgb(255 255 255 / 26%);
  }
  .boton-principal2:hover{
    opacity: 0.50;
    -moz-opacity: .50;
    filter:alpha (opacity=50);
  
  }
  .cont-boton-princ{
    display:flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 0.5rem
  }
  .cont-cont-p2{
    display: flex;
    flex-direction: column;
  } 
  
  @media (min-width:800px){
    .cont-boton-princ{
      display:flex;
      justify-content: center;


    
    }
    .cont-cont-p2{
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin-top: 10rem;
    } 
  }
  .daterangepicker .calendar-table th, .daterangepicker .calendar-table td{
      color: black !important;
  }
  /*SWAL*/
.swal-icon--success__hide-corners{
    background-color: #430218 !important;
  }
  .swal-icon--success__ring{
    border:4px solid #C0EB3A !important
  }
  .swal-icon--success__line{
    background-color :#C0EB3A !important
  }
  .swal-modal{
    background-color:#430218 !important ;
  }
  .swal-title{
    color: white;
  }
  .swal-text{
    color: white; 
  }
  .swal-button{
    background-color:#84AA0F
  }
  .swal-icon--success:after, .swal-icon--success:before{
    background-color:#430218 !important ;
  }
  .swal-overlay{
    background-color:#740B2F;
  }
  .swal-overlay--show-modal{
  background-color: #740B2F;
  }
  .camiseta:hover, .iconos-r-primero:hover, .iconos-redondos:hover {
    transform: scale(1.2);
  }